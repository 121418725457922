import React from 'react';
import { RecipeDangerZone } from './RecipeDangerZone';

const RecipeSettingsView = () => {
  return (
    <>
      <RecipeDangerZone />
    </>
  );
};

export { RecipeSettingsView };
export default { RecipeSettingsView };
