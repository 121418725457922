import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const Login = () => {
  return (
    <div style={{ marginTop: 120 }}>
      <CircularProgress />
    </div>
  );
};

export { Login };
export default { Login };
