import { AppConfig } from './app-config-context-types';

export const initialState: AppConfig = {
  AUTH0_AUDIENCE: '',
  AUTH0_DOMAIN: '',
  AUTH0_CLIENT_ID: '',
  MEDIA_FALLBACK_URL: '',
  TYPESENSE_URL: '',
  TYPESENSE_PATH: '',
  TYPESENSE_API_KEY: '',
};
